<table *ngIf="!loaded" style="height: 100vh; width: 100%;">
    <tbody>
      <tr>
        <td class="align-middle text-center">
          <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
          </div>
        </td>
      </tr>
    </tbody>
</table>
<app-navbar [hidden]="!loaded"></app-navbar>
<router-outlet></router-outlet>
<app-footer [hidden]="!loaded"></app-footer>