import { Component } from '@angular/core';
import * as AOS from 'aos';
import {LoadService} from 'src/app/shared/load.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'xolcy';
  public loaded:boolean = false;

  constructor(private loadService:LoadService){
    this.loadService.loader.subscribe(()=>{
      this.loaded = true;
    })
  }
  
  ngOnInit(): void {
    AOS.init();
  }
}
