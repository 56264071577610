<!-- start navbar -->
<nav class="navbar navbar-expand-lg navbar-light fixed-top sticky" id="navbar" (window:scroll)="windowScroll()">
    <div class="container">
        <a href="#" class="navbar-brand">
            <img src="assets/layout/images/logo-dark.png" class="logo-dark" alt="" height="22" />
        </a>
        <a href="javascript:void(0)" class="navbar-toggler" (click)="toggleMenu()" data-bs-toggle="collapse"
            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggle-icon">
                <i-feather name="menu"></i-feather>
            </span>
        </a>
        <div class="collapse navbar-collapse" id="navbarNav">
            <div ngbDropdown class="dropdown">
                <button ngbDropdownToggle class="btn btn-primary" type="button" id="dropdownMenuButton1" aria-expanded="false">
                {{"navbar.home" | translate}}
                </button>
                <ul ngbDropdownMenu aria-labelledby="dropdownMenuButton1">
                    <li><a ngbDropdownItem (click)="onClick('who-we-are')" href="javascript: void(0);">{{"navbar.who-we-are" | translate}}</a></li>
                    <li><a ngbDropdownItem (click)="onClick('our-history')" href="javascript: void(0);">{{"navbar.our-history" | translate}}</a></li>
                    <li><a ngbDropdownItem (click)="onClick('our-services')" href="javascript: void(0);">{{"navbar.our-services" | translate}}</a></li>
                    <li><a ngbDropdownItem (click)="onClick('our-clients')" href="javascript: void(0);">{{"navbar.our-clients" | translate}}</a></li>
                    <li><a ngbDropdownItem (click)="onClick('news')" href="javascript: void(0);">{{"navbar.news" | translate}}</a></li>
                </ul>
            </div>
            <ul class="navbar-nav mx-auto navbar-center mt-lg-0 mt-2">
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active':currentSection === 'home'}"
                        href="javascript: void(0);" (click)="goTo('/jobs')">
                        <i-feather name="briefcase"></i-feather>
                        <div>
                            {{"navbar.jobs" | translate}}
                        </div>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active':currentSection === 'home'}"
                        href="javascript: void(0);" (click)="goTo('/solutions')">
                        <i-feather name="tool"></i-feather>
                        <div>
                            {{"navbar.solutions" | translate}}
                        </div>
                    </a>
                </li>
            </ul>
            <div ngbDropdown class="dropdown">
                <button ngbDropdownToggle class="btn btn-primary" type="button" id="dropdownMenuButton1" aria-expanded="false">
                {{"navbar.selectLanguage" | translate}}
                </button>
                <ul ngbDropdownMenu aria-labelledby="dropdownMenuButton1">
                    <li><a ngbDropdownItem (click)="selectLanguage('es')" href="javascript: void(0);">{{"navbar.language.spanish"|translate}}</a></li>
                    <li><a ngbDropdownItem (click)="selectLanguage('en')" href="javascript: void(0);">{{"navbar.language.english"|translate}}</a></li>
                </ul>
            </div>
        </div>
    </div>
    <!-- end container -->
</nav>
<!-- end navbar -->
