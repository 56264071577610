import { Component, OnInit} from '@angular/core';
import {Router} from '@angular/router'
import { ViewportScroller } from '@angular/common';

import {TranslateService} from '@ngx-translate/core';
import { delay, tap } from "rxjs/operators";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  currentSection = 'home';
  
  constructor(private viewportScroller: ViewportScroller, private router: Router, private translateService:TranslateService) { }

  ngOnInit(): void {
    
  }

  public selectLanguage(lang:string):void{
    this.translateService.use(lang)
  }

  /**
   * Window scroll method
   */
  // tslint:disable-next-line: typedef
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
      if (navbar !== null) {
        navbar.classList.add('nav-sticky');
      }
    }
    else {
      if (navbar !== null) {
        navbar.classList.remove('nav-sticky');
      }
    }
  }

  public onClick(elementId: string): void { 
      this.router.navigateByUrl('/home').then(x=>{
        setTimeout(()=>{
          this.viewportScroller.scrollToAnchor(elementId);
        },1000)
        
      })
      
  }

  public goTo(url: string):void{
    this.router.navigateByUrl(url).then(x=>{
      setTimeout(()=>{
        this.viewportScroller.scrollToPosition([0,0])
      },1000)
    })
  }

  /**
   * Toggle navbar
   */
   toggleMenu() {
    const nav = document.getElementById('navbarNav');
    if (nav !== null) {
      nav.classList.toggle('show');
    }
  }
  

}
